<template>
  <v-content style="padding: 0px 0px 0px">
    <section>
      <v-parallax
            dark
            src="@/assets/img/icons/recursos-humanos_portada.jpg"
            class="height: 700px"
      >
            <v-layout
            align-center
            column
            justify-center
            >
                <span class="font-weight-thin">
                      <v-img 
                        style="max-width: 100px; max-height: 100px; 
                              display: block;
                              margin-left: auto;
                              margin-right: auto;"
                        src="@/assets/img/icons/sello-portada.png"
                      ></v-img><br><br> 
                      <span style="font-size:70px; font-family: 'Maven Pro', sans-serif;">
                          Recursos Humanos
                      </span>
                </span>
            </v-layout>
      </v-parallax>
    </section>

    <section>
      <v-container fluid grid-list-md >
        <v-layout justify-space-around >
          <v-flex hidden-md-and-down >
            <v-layout column pl-5 ml-5>
              <v-img 
              aspect-ratio="2.5" contain
              height="300px"
              width="300px"
              src="@/assets/img/icons/GenialHUB_logo2.png"></v-img>
              <div>
                <div class="pt-3 subheading text-xs-center black--text" style="font-family: 'Maven Pro', sans-serif;">
                    Obtener, mantener y desarrollar el capital<br> humano 
                    que cumpla de manera eficiente con los Valores<br> y 
                    Objetivos de la Organización por medio de una<br> 
                    planeación estratégica del área de capital humano,<br> 
                    Formación y Atracción de Talento, Desarrollo<br>
                    Organizacional, Cultura y Comunicación de las<br>
                    Organizaciones.<br>
                </div>
              </div>
              <br><br><br>
              <v-avatar size="250px" aspect-ratio="1" contain pl-5 ml-5>
                <v-img
                    src="@/assets/img/icons/rh.jpg"
                    alt=""
                >
                </v-img>
              </v-avatar>     
            </v-layout>
          </v-flex>
          <v-divider
            class="mx-3"
            inset
            vertical
          ></v-divider>
          <v-flex d-flex xs12 sm6 md7>
              <v-layout column pl-5 ml-5>
                <div class="pt-3 text-xs-center subheading black--text" style="font-family: 'Maven Pro', sans-serif;">
                    <div class="text-xs-center">
                        <v-btn light="true" round input-value="off" depressed="true" color="primary" dark style="font-family: 'Maven Pro', sans-serif;">
                            <b>Alcances</b>
                        </v-btn>
                    </div>
                    <h4 style="font-family: 'Maven Pro', sans-serif;">
                        
                    </h4><br>
                    <div>
                        <ul class="subhebody-2 text-xs-left" style="font-family: 'Maven Pro', sans-serif;">
                            <li>Políticas y Procedimientos de Capital Humano.</li>
                            <li>Manual de Capacitación y Reglamento Interno de Trabajo.</li>
                            <li>Métodos de Evaluación y Diagnóstico del Desempeño.</li>
                            <li>Identidad Corporativa y Gestión de Talentos.</li>
                        </ul>
                    </div>
                    <br><br>
                    <div class="text-xs-left">
                        El impacto de los cambios y la modernidad se percibe esencialmente 
                        en la cultura de las organizaciones, en esa “manera de hacer las cosas”.
                         Esto implica modificaciones en el papel de los equipos de gerentes, en 
                         los estilos de liderazgos y en las relaciones entre departamentos y áreas,
                          entre gerentes y colaboradores, es decir, en los Recursos Humanos. 
                          La apertura al cambio y la flexibilidad de quienes dirigen las empresas 
                          es un factor vital para que los procesos de transformación se consoliden 
                          adecuadamente. <br>

                        El gerente moderno, tanto en Recursos Humanos como en otras áreas, 
                        debe convertirse en agente de cambio para manejar estos procesos, 
                        generar compromiso con ellos y garantizar que se produzca el cambio 
                        deseado. La improvisación no es válida, por lo que nuestro programa 
                        de asesoría en Recursos Humanos <b style="color:#880E4F">debe garantizar por un lado una correcta 
                        organización del talento y por el otro, un buen clima laboral.</b> 
                    </div>
                </div>
              </v-layout>

          </v-flex>
          
        </v-layout>
      </v-container>
    </section>

    <section>
      <v-card style="background-color:#CB6CE6">
          <br><br>
        <v-img
            style="max-width: 100px; max-height: 100px; 
                    display: block;
                    margin-left: auto;
                    margin-right: auto;"
            src="@/assets/img/icons/sello-portada.png"
        >                  
        </v-img>    <br>
        <v-layout align-center
            column
            justify-center>
            <span style="font-size:60px; color:white; font-family: 'Maven Pro', sans-serif;">
                Acércate a los expertos
            </span>
            <span style="font-size:40px; color:white; font-family: 'Maven Pro', sans-serif;">
                
            </span>
            <br><br>
        </v-layout>
        
      </v-card>
    </section>

    <!-- <section>
      <v-container fluid grid-list-md>
        <v-layout justify-space-around>
          <v-flex d-flex xs12 sm6 md7>
            <div
                id="e3"
                style="max-width: 500px; margin: auto; padding-top:50px"
                class=""
                >  
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScfBlZOtcg_BMq3jJjbfMDHSM32FGeUETOoM0XFcwN_bxkhng/viewform?embedded=true"
                 width="640" height="725" frameborder="0" marginheight="0" marginwidth="0">Cargando...</iframe>
            </div>
          </v-flex>
          <v-flex xs5 hidden-md-and-down>
            <v-layout column>
              <v-img 
              aspect-ratio="2.5" contain
              src="@/assets/img/icons/GenialHUB_logo2.png"></v-img>
              <div class="display-1 py-5 text-md-center">
                <h4 class="font-weight-thin" style="font-family: 'Maven Pro', sans-serif;">
                    Todo lo que <br> necesita un corporativo <br>
                 <b class="font-weight-bold" style="color:#CB6CE6">en un solo lugar</b></h4>
              </div>
              <v-img 
              aspect-ratio="3" contain
              src="@/assets/img/icons/MX-naranja.png">
              </v-img>
              <div class="headline py-5 text-md-center">
                <h5 class="font-weight-thin" style="font-family: 'Maven Pro', sans-serif;">Con servicio en todo México</h5>
              </div>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </section> -->

    <section id="contacto">
      <v-card xs5 hidden-md-and-down>
        <v-img
          src="@/assets/img/icons/recursos-humanos_pie.jpg"
          height="260"
          
        >          
        <br>
          <v-card-text class="pt-5 pr-5 mr-5 display-1 text-xs-center font-weight-light white--text">
            <p style="font-family: 'Maven Pro', sans-serif;">¡ Contáctanos !</p>
          </v-card-text>
          <v-card-text class="title text-xs-center font-weight-thin white--text">
            <p style="font-family: 'Maven Pro', sans-serif;">gveronica04@hotmail.com | +52 (922) 22 80639</p>
          </v-card-text>
        </v-img>
      </v-card>
    </section>

    <section>
      <v-footer
      height="auto"
      color="#CB6CE6"
     >
       <v-layout
       justify-space-around row fill-height
        wrap
       >
          <V-dialog v-model="dialog9" width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Términos y condiciones</span>
              </v-card-title>
              <v-card-text
                >
                <p>Esta página de Internet y su contenido, incluyendo los textos, gráficos, logos e imágenes, son propiedad de VERÓNICA ALEJANDRA GARCÍA CASTILLO. Está prohibida su reproducción total o parcial, su traducción, inclusión, transmisión, almacenamiento o acceso a través de medios analógicos, digitales o de cualquier otro sistema o tecnología creada, sin autorización previa y escrita de VERÓNICA ALEJANDRA GARCÍA CASTILLO.
                Los usuarios pueden recuperar, exhibir, imprimir, copiar o descargar cualquier material de este sitio exclusivamente para uso personal y no comercial, siempre y cuando se haga expresa mención de la propiedad en cabeza de VERÓNICA ALEJANDRA GARCÍA CASTILLO.
                Con respecto a los contenidos que aparecen en la página Web de VERÓNICA ALEJANDRA GARCÍA CASTILLO, el usuario se obliga a:
                Usar los contenidos de forma diligente, correcta y lícita.
                No suprimir, eludir, o manipular el copryght (derecho de autor) y demás datos que identifican los derechos de la empresa VERÓNICA ALEJANDRA GARCÍA CASTILLO 
                VERÓNICA ALEJANDRA GARCÍA CASTILLO no será responsable del uso indebido que hagan los usuarios de su página Web.
                El visitante o usuario de la página Web de VERÓNICA ALEJANDRA GARCÍA CASTILLO no enviará o transmitirá en este sitio Web o hacía el mismo, a otros usuarios o a cualquier persona, cualquier información de alcance obsceno, difamatorio, injuriante, calumniante contra VERÓNICA ALEJANDRA GARCÍA CASTILLO o contra los responsables de la administración de la página Web.
                El visitante o usuario de este sitio Web, no incurrirá en y desde el mismo, en conductas ilícitas como daños o ataques informáticos, interceptación de comunicaciones, infracciones al derecho de autor, uso no autorizado de terminales, usurpación de identidad, revelación de secretos o falsedad en los documentos.
                Información y sitios Web de terceros
                La página Web puede ofrecer hipervínculos o accesos a páginas Web y contenidos de otras personas, con el propósito de informar al Usuario sobre la existencia de otras fuentes susceptibles de ampliar los contenidos que ésta ofrece, o que guardan relación con aquéllos. VERÓNICA ALEJANDRA GARCÍA CASTILLO no controla, refrenda ni garantiza el contenido incluido en dichos sitios, tampoco se responsabiliza del funcionamiento o accesibilidad de las páginas Web vinculadas, ni sugiere, invita o recomienda la visita a las mismas, por lo que tampoco será responsable del resultado obtenido.
                El usuario acepta que la empresa no es responsable de ningún contenido, enlace asociado, recurso o servicio relacionado con el sitio de un tercero. Asimismo, el Usuario acepta que VERÓNICA ALEJANDRA GARCÍA CASTILLO no será responsable de ninguna perdida o daño de cualquier tipo que se derive del uso que se realice de los contenidos de un tercero.
                VERÓNICA ALEJANDRA GARCÍA CASTILLO  no se responsabiliza por cualquier consecuencia derivada del ingreso indebido de terceros a la base de datos y/o por alguna falla técnica en el funcionamiento y/o conservación de datos en el sistema en cualquiera de los menús de su página Web.
                Igualmente, VERÓNICA ALEJANDRA GARCÍA CASTILLO no podrá garantizar la disponibilidad de los servicios en línea y de la información que los usuarios requieran en determinado momento. Tampoco incurrirá en responsabilidad con el usuario o terceros, cuando su página Web no se encuentre disponible.
                VERÓNICA ALEJANDRA GARCÍA CASTILLO no responderá en ningún caso y bajo ninguna circunstancia, por los ataques o incidentes contra la seguridad de su página Web o contra sus sistemas de información; o por cualquier exposición o exceso no autorizado, fraudulenta o ilícito a su página Web y que puedan afectar las confidencialidad, integridad o autenticidad de la información publicada o asociada con los contenidos y servicios que se ofrecen en ella.
                Responsabilidad por la información contenida
                Debido a que en la actualidad los medios técnicos no permiten garantizar la absoluta falta de injerencia de la acción de tercera personas en las páginas Web, VERÓNICA ALEJANDRA GARCÍA CASTILLO de ninguna manera asegura la exactitud y/o veracidad de todo o parte de la información contenida en su página, ni de su actualización, ni que dicha información haya sido alterada o modificada en todo o en parte, luego de haber sido publicada en la página, ni cualquier otro aspecto o característica de lo publicado en el sitio o en los enlaces, respectivamente.
                VERÓNICA ALEJANDRA GARCÍA CASTILLO no controla ni garantiza la ausencia de virus ni de otros elementos en los contenidos que puedan producir alteraciones en su sistema informático (software y hardware) o en los documentos electrónicos y ficheros almacenados en su sistema informático.
                Ley aplicable y jurisdicción
                El usuario no podrá manifestar ante VERÓNICA ALEJANDRA GARCÍA CASTILLO o ante a una autoridad judicial o administrativa, la aplicación de condición, norma o convenio que no esté expresamente incorporado en las presentes condiciones de uso.
                Estas condiciones serán gobernadas por la leyes de la República Mexicana, en los aspectos que no estén expresamente regulados en ellas.
                Si cualquier disposición de estas condiciones pierde validez o fuerza obligatoria. por cualquier razón, todas las demás disposiciones, conservan su fuerza obligatoria, carácter vinculante y generarán todos sus efectos.
                Para cualquier efecto legal o judicial, el lugar de las presentes condiciones es la ciudad de Minatitlán, y cualquier controversia que surja de su interpretación o aplicación se someterá  los jueces de la República Mexicana.
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" flat="flat" @click="dialog9 = false">Lo he leído</v-btn>
              </v-card-actions>
            </v-card>
          </V-dialog>
          <v-dialog v-model="dialog10" width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Política de Privacidad</span>
              </v-card-title>
              <v-card-text
                >
                <p>VERÓNICA ALEJANDRA GARCÍA CASTILLO  (en adelante VAC), con domicilio en Santiago de Chile #31, Colonia Nueva Mina Norte, Minatitlán, Ver. México, C.p. 96734, con el RFC: GACV780418PK3 es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.
                  DATOS PERSONALES QUE RECABAMOS
                  VERONICA ALEJANDRA GARCÍA CASTILLO podrá recabar de usted los siguientes datos personales: nombre completo, domicilio, RFC, teléfono, correo electrónico, datos bancarios, referencias personales. Asimismo, podremos solicitar de Usted copia de identificación oficial, comprobante de domicilio, contrato de arrendamiento de inmueble o comprobantes de pago de predial, en su caso.
                  USO DE SUS DATOS PERSONALES
                  Sus datos personales serán utilizados para las siguientes finalidades: (i) proveer los servicios y productos requeridos por Usted; (ii) informar sobre cambios o nuevos productos que estén adquiridos por el cliente; (iii) dar cumplimiento a obligaciones contraídas con Usted; (d) evaluar la calidad del servicio; (iv) enviarle ofertas, promociones o publicidad relacionadas con nuestros productos y servicios.
                  TRANSMISIÓN DE DATOS
                  Nos comprometemos a no transferir su información personal a terceros sin su consentimiento. salvo las excepciones previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
                  ACCESSO, RETIFICACIÓN, CANCELACIÓN Y OPOSICIÓN DE DATOS PERSONALES
                  Usted tiene derecho a acceder a sus datos personales que poseemos y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o incompletos; cancelarlos cuando considere que no se requieren para alguna de las finalidades señalados en el presente aviso de privacidad, estén siendo utilizados para finalidades no consentidas o haya finalizado la relación de servicio, o bien, oponerse al tratamiento de los mismos para fines específicos.
                  Los mecanismos que se han implementado para el ejercicio de dichos derechos son a través de la presentación de la solicitud respectiva ante nuestra área de servicio al cliente:
                                        * Número telefónico: 922 22 806 39
                                        * Correo electrónico: gveronica04@hotmail.com
                                        * Domicilio:   Santiago de Chile #31, Colonia Nueva Mina Norte, Minatitlán, Ver. México, C.p. 96734
                  Podrá presentar su solicitud en cualquiera de los medios de comunicación mencionados anteriormente.
                  Su solicitud deberá contener la siguiente información: (i) nombre completo, (ii) derecho que desee ejercer, (iii) datos que requiera acceder, ratificar, cancelar u oponerse a su tratamiento, (iv) en caso que se solicite la rectificación o actualización de sus datos, deberá proporcionar el dato que sustituirá el anterior, (v) en caso de oposición al trato de sus datos, deberá indicar el dato personal y el uso al cual se opone, (vi) en caso de cancelación o acceso, deberá indicar el dato a cancelar o acceder, según sea el caso.
                  Para mayor información, favor de comunicarse al área de servicio al cliente mencionada anteriormente.
                  REVOCACIÓN DE CONSENTIMIENTO
                  En todo momento, Usted podrá revocar el consentimiento que nos ha otorgado para el tratamiento de su datos personales, a fin de que dejemos de hacer uso de los mismos. Para ello, es necesario que presente su petición en el área de servicio al cliente, de conformidad con el procedimiento establecido en el punto anterior.
                  Si como consecuencia de la revocación de su consentimiento no es posible brindar el servicio o producto contratado por Usted, se entenderá que el mismo se ha dado por terminado por parte suya, sin ninguna responsabilidad para VERONICA ALEJANDRA GARCÍA CASTILLO.
                  MODIFICACIONES AL AVISO DE PRIVACIDAD
                  Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones a las presentes políticas de privacidad. Estas modificaciones estarán disponibles al público a través de los siguientes medios: (i) anuncios visibles en nuestros establecimientos o centros de atención a clientes, (ii) trípticos o folletos disponibles en nuestro establecimientos o centros de atención a clientes, (iii) en nuestra página de Internet https://www.veronicaalejandra.com sección de políticas de privacidad, (iv) o se la haremos llegar al último correo electrónico que nos haya proporcionado.
                  Fecha de última actualización: Diciembre 04 de 2024
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" flat="flat" @click="dialog10 = false">Lo he leído</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <v-flex
          black
          py-3
          text-xs-right
          white--text
          xs12
          row
        >
          <v-btn unhover class="black white--text mr-5 pr-5">
            <h4><strong>VerónicaAlejandra</strong> &copy;2024</h4>
          </v-btn>
          
          <v-btn disabled class="black white--text mr-5 pr-5"></v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            class="ml-5 font-weight-thin caption"
            slot="activator" color="white"
            flat
            @click="dialog9 = true"
            ><h4>Términos y condiciones</h4>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="font-weight-thin caption"
            slot="activator" color="white"
            flat
            @click="dialog10 = true"
            ><h4>Política de Privacidad</h4>
          </v-btn>
          <v-spacer></v-spacer>
        </v-flex>
       </v-layout>
      </v-footer>
    </section>
    
  </v-content>
</template>

<script>
export default {
    
    data: () => ({

      dialog9: false,
      dialog10: false,
      icons: [
       
      ],
      items: [
          {src: '@/assets/img/icons/Fondo1.jpg'}
        ],
    }),
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  h2, h4, h5, h6, {
    font-family: 'Maven Pro', sans-serif;
  }

  .v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}

</style>
