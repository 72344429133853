import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import Nosotros from '@/components/NoSotros'
import Contacto from '@/components/conTacto'
import fiscal from '@/components/fisCal'
import marketing from '@/components/marKeting'
import rh from '@/components/rH'
import tecnologia from '@/components/tecnoLogia.vue'
import formulario from '@/components/forMulario'
Vue.use(Router)

export default new Router({
  mode: 'history', // Utiliza el modo 'history' para URLs limpias
  routes: [
    {
      path: '/',
      name: 'HelloWorld',
      component: HelloWorld
    },
    {
      path: '/nosotros',
      name: 'NoSotros',
      component: Nosotros
    },
    {
      path: '/contacto',
      name: 'conTacto',
      component: Contacto
    },
    {
      path: '/fiscal',
      name: 'fiscal',
      component: fiscal
    },
    {
      path: '/marketing',
      name: 'marketing',
      component: marketing
    },
    {
      path: '/rh',
      name: 'rh',
      component: rh
    },
    {
      path: '/tecnologia',
      name: 'tecnologia',
      component: tecnologia,
    },
    {
      path: '/formulario',
      name: 'formulario',
      component: formulario
    },
  ],
  // scrollBehavior (to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // }
})
