<template>

<v-layout row wrap align-center justify-space-around fill-height>
    
    <v-flex d-flex xs2>
        <div>
            <v-btn color="#CB6CE6" dark>
                <v-icon dark left>fa fa-phone</v-icon>+52 (922) 22 80639
            </v-btn>
            <v-btn color="#CB6CE6" dark href="mailto:gveronica04@hotmail.com">
                <v-icon dark left>fas fa-envelope</v-icon>
                gveronica04@hotmail.com
            </v-btn>
            <!-- <v-btn fab dark large color="#CB6CE6" target="_blank" 
                href="https://www.facebook.com/Genial-Hub-Consultores-540498249719597/">
                <i class="fab fa-facebook-square fa-3x"></i>
            </v-btn> -->
        </div>
        <!--<v-card width="470" height="320">
            <v-toolbar
            card
            color="#3a3c39"
            dark
            >
            <v-toolbar-title>Envíanos tus dudas o comentarios... </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon>send</v-icon>
            </v-toolbar>
            <v-form>
            <v-text-field
                label="eMail"
                value=""
                single-line
                full-width
                hide-details
            ></v-text-field>
            <v-divider></v-divider>
            <v-text-field
                label="Tema"
                value=""
                single-line
                full-width
                hide-details
            ></v-text-field>
            <v-divider></v-divider>
            <v-textarea
                v-model="title"
                label="Mensaje"
                counter
                maxlength="120"
                full-width
                single-line
            ></v-textarea>
            </v-form>
        </v-card>-->
    </v-flex>
    <v-flex d-flex xs4 >
        <v-card>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.0389386050297!2d-89.59621258591244!3d20.991076194441515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f56712e8fea7881%3A0x83b6a851b1d52dc0!2zQ2FsbGUgMzHhtK4gMTQ1LCBOdWV2YSBBbGVtw6FuLCA5NzE0NiBNw6lyaWRhLCBZdWMu!5e0!3m2!1ses!2smx!4v1544815314466"
                 width="460" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
            <v-card-title>
                <div>
                <span class="grey--text"></span><br>
                <span>Verónica Alejandra</span><br>
                <span></span>
                </div>
            </v-card-title>
        </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  export default {
    data () {
      return {
        
        title: ''
      }
    }
  }
</script>