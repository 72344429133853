<template>
  <v-app>
    <v-toolbar flat app color="black">
          <div>
            <v-btn icon color="white" class="hidden-md-and-up show-sm-and-down" router to="/">
              <v-icon >home</v-icon>
            </v-btn>
            <a href="">
            <img
              src="./assets/Logo-Encabezado.png"
              alt="logo"
              style="width: 210px; heigth: 150px; "
              router to="/"
              class="hidden-sm-and-down"
            ></a>
          </div>
        <v-spacer></v-spacer>
        <v-toolbar-items class="show-md-and-up hidden-sm-and-down">
          <!-- <v-btn class="white--text" flat round router to ="/"><h4>Inicio</h4></v-btn> -->
          <div class="text-xs-center mt-3">
            <v-btn round color="grey" dark router to ="/">Inicio</v-btn>
          
            <!-- <v-menu offset-y>
              <v-btn round color="grey" dark slot="activator"><h4>Servicios</h4></v-btn>
              <v-list class="black" style="height:400px; opacity:.5">
                <v-list-tile
                  style="font-size:10px; font-family: 'Maven Pro', sans-serif; heigth:200px"
                  class="white--text"
                  column
                >
                  <v-layout align-center justify-start column fill-height>
                    <v-btn class="transparent white--text" :to="{ name: 'fiscal' }">Consultoría Fiscal</v-btn>
                    <v-btn class="transparent white--text" :to="{ name: 'rh' }">Recursos Humanos</v-btn>
                    <v-btn class="transparent white--text" :to="{ name: 'marketing' }">Marketing</v-btn>
                    <v-btn class="transparent white--text" :to="{ name: 'legal' }">Asesoría legal</v-btn>
                    <v-btn class="transparent white--text" :to="{ name: 'logistica' }">Logística Comercial</v-btn>
                    <v-btn class="transparent white--text" :to="{ name: 'outsourcing' }">Administración de Nómina</v-btn>
                    <v-btn class="transparent white--text" :to="{ name: 'gob' }">Gobierno Corporativo</v-btn>
                    <v-btn class="transparent white--text" :to="{ name: 'contraloria' }">Contraloría Externa</v-btn>
                  </v-layout>
                </v-list-tile>
              </v-list>
            </v-menu> -->
            <v-btn round class="mx-2" color="grey" dark router to ="/Nosotros"><h4>Nosotros</h4></v-btn>
            <v-btn round color="grey" dark href="#contacto"><h4>Contacto</h4></v-btn>
          </div>
        </v-toolbar-items>

        <v-menu class="show-sm-and-down hidden-md-and-up" offset-x>
          <v-toolbar-side-icon icon color="white" slot="activator"></v-toolbar-side-icon>
            <v-btn class="white--text" flat rounded small router to ="/"><h4>Inicio</h4></v-btn>
          <v-menu offset-y>
            <v-btn class="white--text" flat round slot="activator" href="/#servicios"><h4>Servicios</h4></v-btn>
            <v-list class="black" style="height:400px; opacity:.5">
              <v-list-tile
                style="font-size:10px; font-family: 'Maven Pro', sans-serif; heigth:200px"
                class="white--text"
                column
              >
                <v-layout align-center justify-start column fill-height>
                  <v-btn class="transparent white--text" :to="{ name: 'fiscal' }">Consultoría Fiscal</v-btn>
                  <v-btn class="transparent white--text" :to="{ name: 'rh' }">Recursos Humanos</v-btn>
                  <v-btn class="transparent white--text" :to="{ name: 'marketing' }">Marketing</v-btn>
                  <v-btn class="transparent white--text" :to="{ name: 'legal' }">Asesoría legal</v-btn>
                  <v-btn class="transparent white--text" :to="{ name: 'logistica' }">Logística Comercial</v-btn>
                  <v-btn class="transparent white--text" :to="{ name: 'outsourcing' }">Administración de Nómina</v-btn>
                  <v-btn class="transparent white--text" :to="{ name: 'gob' }">Gobierno Corporativo</v-btn>
                  <v-btn class="transparent white--text" :to="{ name: 'contraloria' }">Contraloría Externa</v-btn>
                </v-layout>
              </v-list-tile>
            </v-list>
          </v-menu>
          <v-btn class="white--text" flat round router to ="/Nosotros"><h4>Nosotros</h4></v-btn>
          <v-btn class="white--text" flat round href="#contacto"><h4>Contacto</h4></v-btn>
        </v-menu>
      </v-toolbar>

    <v-main>
      <router-view></router-view> <!-- Aquí se renderizarán los componentes enrutados -->
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {},
};
</script>

<style>
/* Estilos globales */
</style>
