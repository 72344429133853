<template>
  <v-content style="padding: 0px 0px 0px">
    <section>
      <!--<div>
        <v-carousel hide-delimiters hide-controls>
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            :src="item.src"
            
          >
            <v-window v-model="onboarding">
              
              <v-window-item
                v-for="n in length"
                :key="`card-${n}`"
              >
                <v-card
                  color="transparent"
                  height="600"
                >
                
                  <v-layout
                    align-center
                    justify-center
                    fill-height
                    tag="v-card-text"
                    display-1
                    text-xs-center
                  >
                    
                  
                    <span class="font-weight-thin">
                      <v-img 
                        style="max-width: 100px; max-height: 100px; 
                              display: block;
                              margin-left: auto;
                              margin-right: auto;"
                        src="@/assets/img/icons/sello-portada.png"
                      ></v-img><br>
                      <span style="font-size:70px; font-family: 'Maven Pro', sans-serif;">
                        Somos el complemento perfecto <br> 
                      <b>para tu empresa</b></span>
                    </span>
                  </v-layout>
                  

                </v-card>
              </v-window-item>
            </v-window>

          </v-carousel-item>
        </v-carousel>
        <v-layout>
          <v-card
            class="portrait"
            img="@/assets/img/icons/Fondo1.jpg"
            height="660"
            width="1420"
            flat
          ></v-card>
        </v-layout>
      </div>-->
      
      <v-parallax
            dark
            src="@/assets/img/icons/outsourcing_portada.jpg"
            height= "650"
       >
            <v-layout
            pt-5
            align-center
            column
            justify-center
            style="margin-top:80px"
            >
                <span class="font-weight-thin">
                      <v-img pt-5
                        style="max-width: 100px; max-height: 100px; 
                              display: block;
                              margin-left: auto;
                              margin-right: auto;"
                        src="@/assets/img/icons/sello-portada.png"
                      ></v-img><br><br>
                      <div class="text-xs-center">
                        <span style="font-size:45px; font-family: 'Maven Pro', sans-serif;">
                          Somos el complemento perfecto<br>
                          <b>para tu empresa</b>
                        </span>
                      </div>
                </span>
            </v-layout>
            <br><br><br><br><br><br>
              <v-layout class="mb-5" align-end justify-space-around fill-height hidden-md-and-down>                
                <v-flex md1>
                  <v-card class="text-xs-center" color="transparent" flat style="font-size:15px; font-family: 'Maven Pro', sans-serif;">
                    <v-btn class="transparent white--text" :to="{ name: 'fiscal' }">
                      Consultoría <br> Fiscal
                    </v-btn>
                  </v-card>
                </v-flex>
                <v-flex md1>
                  <v-card class="text-xs-center" color="transparent" flat style="font-size:15px; font-family: 'Maven Pro', sans-serif;">
                    <v-btn class="transparent white--text" :to="{ name: 'rh' }">Recursos <br> Humanos</v-btn>
                  </v-card>
                </v-flex>
                <v-flex md1>
                  <v-card class="text-xs-center" color="transparent" flat style="font-size:15px; font-family: 'Maven Pro', sans-serif;">
                    <v-btn class="transparent white--text" :to="{ name: 'marketing' }">Marketing</v-btn>
                  </v-card>
                </v-flex>
                <v-flex md1>
                  <v-card class="text-xs-center" color="transparent" flat style="font-size:15px; font-family: 'Maven Pro', sans-serif;">
                    <v-btn class="transparent white--text" :to="{ name: 'tecnologia' }">Desarrollo web-App <br> Tecnología</v-btn>
                  </v-card>
                </v-flex>
              </v-layout>
      </v-parallax>
    </section>

    <section id="servicios">
      <v-container fluid grid-list-md>
        <v-layout justify-space-around>
          <v-flex xs5 hidden-md-and-down>
            <v-layout column pl-5 ml-5>
              <v-img 
              aspect-ratio="2.5" 
              contain
              src="@/assets/img/icons/GenialHUB_logo2.png">
              </v-img>
              <div class="display-1 py-5 text-xs-center">
                <h4 class="font-weight-thin">Todo lo que <br> necesita un corporativo <br>
                 <b class="font-weight-bold" style="color:#CB6CE6">en un solo lugar</b></h4>
              </div>
              <v-img 
              aspect-ratio="2" contain
              src="@/assets/img/icons/MX-naranja.png">
              </v-img>
              <div class="headline py-5 text-xs-center">
                <h5 class="font-weight-thin">Con servicio en todo México</h5>
              </div>
            </v-layout>
          </v-flex>
          <v-flex d-flex xs12 sm6 md6>
            <v-layout row wrap>
              <!-- <v-flex
                d-flex
                xs12
                hidden-md-and-down
               >
                <v-card
                  color="transparent"
                  flat
                >
                  <v-card
                    class="portrait"
                    img="@/assets/img/icons/Tramado1.png"
                    height="110"
                    width="270"
                    style="margin-left:250px"
                    flat
                  ></v-card>
                </v-card>
              </v-flex> -->
              <v-flex d-flex xs12>
                <v-card color="transparent" flat>
                  <v-layout align-center justify-space-around row fill-height>
                    <div
                      class="mx-auto"
                      width=""
                      >
                      <div class="text-xs-center">
                        <v-dialog
                          v-model="dialog1"
                          width="600"
                         >
                         <template v-slot:activator="{ on, attrs }">
                          <v-hover 
                            slot="activator"
                            color="deep-orange accent-4"
                            dark>
                            <v-avatar size="150px" slot-scope="{ hover }"
                              :class="`elevation-${hover ? 12 : 2}`" v-bind="attrs"
                              v-on="on">
                                <v-img
                                  src="@/assets/img/icons/picfiscal.jpg"
                                  alt=""
                                >
                                  <v-expand-transition>
                                    <div
                                      v-if="hover"
                                      class="d-flex transition-fast-in-fast-out 
                                      purple accent-3 v-card--reveal display-6 white--text"
                                      style="height: 100%;"
                                    >
                                      Ver más
                                    </div>
                                  </v-expand-transition>
                                </v-img>
                            </v-avatar>      
                          </v-hover>
                          </template>
                          
                          <v-flex xs12>
                              <v-card color="white" class="white--text">
                                  <v-layout>
                                    <v-img
                                      class="white--text"
                                      height="auto"
                                      src="@/assets/img/icons/Consultoria-fiscal_pre.jpg"
                                    >
                                      <v-container fill-height fluid>
                                        <v-layout fill-height>
                                          <v-flex xs12 align-end flexbox>
                                            <br><br>
                                            <span class="headline" style="font-family: 'Maven Pro', sans-serif;">
                                              Consultoría Fiscal
                                            </span><br><br>
                                            
                                            <div>
                                                <ul class="subheading" style="font-family: 'Maven Pro', sans-serif;">
                                                  <li> Administración y Asesoría en <br>Litigio Fiscal.</li><br>
                                                  <li>Asesoría a Emprendedores.</li><br>
                                                  <li>Contabilidad Administrativa y Fiscal.</li><br>
                                                  <li>Planificación de Activos y Patrimonios.</li><br>
                                                </ul>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-container>
                                    </v-img>
                                  </v-layout>
                              </v-card>
                          </v-flex>
                        </v-dialog>
                      </div>
                          <div>
                            <h2 class="font-weight-thin text-xs-center">Consultoría <br> Fiscal</h2>
                          </div> 
                    </div>
                    <v-spacer></v-spacer>
                    <div
                      class="mx-auto"
                      width=""
                      >
                      <div class="text-xs-center">
                        <v-dialog
                          v-model="dialog2"
                          width="600"
                        >
                        <template v-slot:activator="{ on, attrs }">
                          <v-hover 
                            slot="activator"
                            color="red lighten-2"
                            dark>
                            <v-avatar size="150px" slot-scope="{ hover }"
                              :class="`elevation-${hover ? 12 : 2}`" v-bind="attrs"
                              v-on="on">
                                <v-img
                                  src="@/assets/img/icons/rh.jpg"
                                  alt=""
                                >
                                  <v-expand-transition>
                                    <div
                                      v-if="hover"
                                      class="d-flex transition-fast-in-fast-out 
                                      purple accent-3 v-card--reveal display-6 white--text"
                                      style="height: 100%;"
                                    >
                                      Ver más
                                    </div>
                                  </v-expand-transition>
                                </v-img>
                            </v-avatar>      
                          </v-hover>
                        </template>
                          <v-flex xs12>
                              <v-card color="white" class="white--text">
                                  <v-layout>
                                    <v-img
                                      class="white--text"
                                      height="auto"
                                      src="@/assets/img/icons/recursos-humanos_pre.jpg"
                                    >
                                      <v-container fill-height fluid>
                                        <v-layout fill-height>
                                          <v-flex xs12 align-end flexbox>
                                            <br><br>
                                            <span class="headline" style="font-family: 'Maven Pro', sans-serif;">
                                              Recursos Humanos
                                            </span><br><br>
                                            <div>
                                                <ul class="subheading" style="font-family: 'Maven Pro', sans-serif;">
                                                  <li> Políticas y Procedimientos de <br> Capital Humano.</li><br>
                                                  <li>Manual de Capacitación y <br>
                                                      Reglamento Interno de Trabajo.</li><br>
                                                  <li>Métodos de Evaluación y <br>
                                                      Diagnóstico del Desempeño.</li><br>
                                                  <li>Identidad Corporativa y <br> Gestión de Talentos.</li><br>
                                                </ul>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-container>
                                    </v-img>
                                  
                                  </v-layout>
                              </v-card>
                          </v-flex>
                        </v-dialog>
                      </div>
                      <div>
                        <h2 class="font-weight-thin text-xs-center">Recursos <br> Humanos</h2>
                      </div>
                    </div>
                    <div
                      class="mx-auto"
                      width=""
                      >
                      <div class="text-xs-center">
                        <v-dialog
                          v-model="dialog4"
                          width="600"
                        >
                        <template v-slot:activator="{ on, attrs }">
                          <v-hover 
                            slot="activator"
                            color="red lighten-2"
                            dark>
                            <v-avatar size="150px" slot-scope="{ hover }"
                              :class="`elevation-${hover ? 12 : 2}`" v-bind="attrs"
                              v-on="on">
                                <v-img
                                  src="@/assets/img/icons/mark.jpg"
                                  alt=""
                                >
                                  <v-expand-transition>
                                    <div
                                      v-if="hover"
                                      class="d-flex transition-fast-in-fast-out 
                                      purple accent-3 v-card--reveal display-6 white--text"
                                      style="height: 100%;"
                                    >
                                      Ver más
                                    </div>
                                  </v-expand-transition>
                                </v-img>
                            </v-avatar>      
                          </v-hover>
                        </template>
                          <v-flex xs12>
                              <v-card color="white" class="white--text">
                                  <v-layout>
                                    <v-img
                                      class="white--text"
                                      height="auto"
                                      src="@/assets/img/icons/marketing_pre.jpg"
                                    >
                                      <v-container fill-height fluid>
                                        <v-layout fill-height>
                                          <v-flex xs12 align-end flexbox>
                                            <br><br>
                                            <span class="headline" style="font-family: 'Maven Pro', sans-serif;">
                                              Marketing
                                            </span><br><br>
                                            <div>
                                                <ul class="subheading" style="font-family: 'Maven Pro', sans-serif;">
                                                  <li>Generación de contenidos <br>
                                                     para la atracción de potenciales <br>
                                                     clientes.</li><br>
                                                  <li>Automatización de procesos <br> 
                                                      internos y externos para <br> 
                                                      captación y gestión de clientes.</li><br>
                                                  <li>Realización y presentación de <br>
                                                      propuestas de marketing para <br>
                                                      potenciales clientes.</li><br>
                                                </ul>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-container>
                                    </v-img>
                                  </v-layout>
                              </v-card>
                          </v-flex>
                        </v-dialog>
                      </div>
                      <br>
                      <div>
                        <h2 class="font-weight-thin text-xs-center">Marketing</h2>
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                    <div
                      class="mx-auto"
                      width=""
                      >
                      <div class="text-xs-center">
                        <v-dialog
                          v-model="dialog5"
                          width="500"
                        >
                        <template v-slot:activator="{ on, attrs }">
                          <v-hover 
                            slot="activator"
                            color="red lighten-2"
                            dark>
                            <v-avatar size="150px" slot-scope="{ hover }"
                              :class="`elevation-${hover ? 12 : 2}`" v-bind="attrs"
                              v-on="on">
                                <v-img
                                  src="@/assets/img/icons/sist.jpg"
                                  alt=""
                                >
                                  <v-expand-transition>
                                    <div
                                      v-if="hover"
                                      class="d-flex transition-fast-in-fast-out 
                                      purple accent-3 v-card--reveal display-6 white--text"
                                      style="height: 100%;"
                                    >
                                      Ver más
                                    </div>
                                  </v-expand-transition>
                                </v-img>
                            </v-avatar>      
                          </v-hover>
                        </template>
                        <v-flex xs12>
                              <v-card color="white" class="white--text">
                                  <v-layout>
                                    <v-img
                                      class="white--text"
                                      height="auto"
                                      src="@/assets/img/icons/recursos-humanos_pre.jpg"
                                    >
                                      <v-container fill-height fluid>
                                        <v-layout fill-height>
                                          <v-flex xs12 align-end flexbox>
                                            <br><br>
                                            <span class="headline" style="font-family: 'Maven Pro', sans-serif;">
                                              Desarrollo de Tecnología
                                            </span><br><br>
                                            <div>
                                                <ul class="subheading" style="font-family: 'Maven Pro', sans-serif;">
                                                    <li>Desarrollo de Aplicaciones.</li><br>
                                                    <li>Desarrollo Web.</li><br>
                                                    <li>Desarrollo de Chatbots.</li><br>
                                                    <li>Desarrollo y Gestión de <br> Soluciones SaaS.</li><br>
                                                    <li>Consultoría de Funciones y <br> Procesos Resueltos con Software.</li><br>
                                                </ul>
                                            </div>


                                          </v-flex>
                                        </v-layout>
                                      </v-container>
                                    </v-img>
                                  
                                  </v-layout>
                              </v-card>
                          </v-flex>
                        </v-dialog>
                      </div>
                      <div>
                        <h2 class="font-weight-thin text-xs-center">Tecnología <br> Web-App</h2>
                      </div>
                    </div>
                    
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

    <section>
      <!-- <v-card> -->
        <v-img
          src="@/assets/img/icons/Fondo-frase.jpg"
        >       <br><br><br>
          <v-card-text class="display-2 ml-5 pl-5 text-xs-center white--text"><br><br><br>
            <h4 class="font-weight-thin"> "La innovación distingue entre un líder  <br> y un seguidor."</h4>
          </v-card-text>
          <v-card-text class="title ml-2 text-xs-center font-weight-thin font-italic white--text">
            <h5>-Steve Jobs</h5> 
          </v-card-text>
        </v-img>
        
      <!-- </v-card> -->
    </section>

    <!-- <section>
      <v-card style="">
          <br><br>
        <v-img
            style="max-width: 40px; max-height: 40px; 
                    display: block;
                    margin-left: auto;
                    margin-right: auto;"
            src="@/assets/img/icons/GH icon.png"
        >                  
        </v-img>    <br>
        <v-layout align-center
            column
            justify-center>
            <span class="text-sm-center" style="font-size:25px; color:black; font-family: 'Maven Pro', sans-serif;">
                Sé parte de las empresas que <br> ya confian en Genial HUB
            </span>
            <br>
            <span style="font-size:15px; color:#CB6CE6; font-family: 'Maven Pro', sans-serif;">
                Algunas de las industrias satisfechas con nuestros servicios:
            </span>
            <br>
        </v-layout>
        <v-container fluid grid-list-xl hidden-md-and-down>
          <v-layout row justify-space-between>
            <v-flex md1>
              <v-card class="text-xs-center" color="transparent" flat style="font-size:15px; font-family: 'Maven Pro', sans-serif;">
                <v-card-text></v-card-text>
              </v-card>
            </v-flex>
            <v-flex md2>
              <v-card class="text-xs-center" color="transparent" flat style="font-size:15px; font-family: 'Maven Pro', sans-serif;">
                <v-card-text>Construcción de <br><b>Carreteras y Obra Civil</b></v-card-text>
                <v-img
                  src="@/assets/img/icons/linea naranja.png"
                >
                </v-img>
              </v-card>
            </v-flex>
            <v-flex md2>
              <v-card class="text-xs-center" color="transparent" flat style="font-size:15px; font-family: 'Maven Pro', sans-serif;">
                <v-card-text>Mantenimiento de <br><b>Transporte y Maquinaria</b></v-card-text>
                <v-img
                  src="@/assets/img/icons/linea naranja.png"
                  
                >
                </v-img>
              </v-card>
            </v-flex>
            <v-flex md2>
              <v-card class="text-xs-center" color="transparent" flat style="font-size:15px; font-family: 'Maven Pro', sans-serif;">
                <v-card-text>Consultoría de Servicios <br><b>de Administración</b></v-card-text>
                <v-img
                  src="@/assets/img/icons/linea naranja.png"
                >
                </v-img>
              </v-card>
            </v-flex>
            <v-flex md2>
              <v-card class="text-xs-center" color="transparent" flat style="font-size:15px; font-family: 'Maven Pro', sans-serif;">
                <v-card-text>Comercio <br><b> y Distribución</b></v-card-text>
                <v-img
                  src="@/assets/img/icons/linea naranja.png"
                >
                </v-img>
              </v-card>
            </v-flex>
            <v-flex md2>
              <v-card class="text-xs-center" color="transparent" flat style="font-size:15px; font-family: 'Maven Pro', sans-serif;">
                <v-card-text>Taller de Fabricación de <br><b>Muebles y Equipo de Protección</b></v-card-text>
                <v-img
                  src="@/assets/img/icons/linea naranja.png"
                >
                </v-img>
              </v-card>
            </v-flex>
            <v-flex md1>
              <v-card class="text-xs-center" color="transparent" flat style="font-size:15px; font-family: 'Maven Pro', sans-serif;">
                <v-card-text></v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
        
      </v-card>
    </section> -->

    <section id="contacto" >
      <v-container style="background-color:#EAEAEA" fluid px-0 mx-0 >
        <v-layout  row hidden-md-and-down>
          <v-flex hidden-md-and-down md1>
            <br><br><br><br><br><br><br>
            <v-card img="@/assets/img/icons/Tramado.png"
                    height="100"
                    width="100"
                    flat
                    >
            </v-card>
            
          </v-flex>
          <v-flex xs12 md5>
            <br>
            <v-card tile flat style="background-color:#EAEAEA">
              <v-card-title class="pl-5 display-2 font-weight-light">
                <h4>Contacto</h4>
                <br>
              </v-card-title>
              <!--<v-list-tile>
                <v-list-tile-avatar class="pl-5">
                  <v-icon style="color:white; background-color:#CB6CE6" >location_on</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title class="ml-3"><h5>Santiago de Chile #31, <br>
                   Colonia Nueva Mina Norte, <br>
                   Minatitlán, Ver. México. <br>
                   C.p. 96734</h5></v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>-->
              <v-list-tile>
                <v-list-tile-avatar class="pl-5">
                  <v-icon style="color:#CB6CE6; " large>mdi-phone</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title class="ml-3"><h5>+52 (922) 22 80639</h5></v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-avatar class="pl-5">
                  <v-icon style="color:#CB6CE6; " large>mdi-email</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title class="ml-3"><h5>gveronica04@hotmail.com</h5></v-list-tile-title>
                  <!-- <v-list-tile-title class="ml-3">
                    <h5><a href="mailto:gveronica04@hotmail.com">gveronica04@hotmail.com</a></h5>
                  </v-list-tile-title> -->
                </v-list-tile-content>
              </v-list-tile>
              <br><br>
              <!-- <div class="text-xs-left pl-5">
                <v-btn round color="#CB6CE6" dark route to="formulario">Solicitar Consulta</v-btn>
              </div> -->
              <!--<div>
                  <v-btn color="#CB6CE6" dark href="mailto:gveronica04@hotmail.com">
                      <v-icon dark left>fas fa-envelope</v-icon>
                      gveronica04@hotmail.com
                  </v-btn>
                  <v-btn fab dark large color="#CB6CE6" target="_blank" 
                      href="https://www.facebook.com/Genial-Hub-Consultores-540498249719597/">
                      <i class="fab fa-facebook-square fa-3x"></i>
                  </v-btn>
              </div>-->
            </v-card>
          </v-flex>
          <v-flex xs12 md6>
              <v-card tile flat color="transparent">
                <v-card-title class="pl-5 display-2 font-weight-light">
                <h4>Dirección</h4>
                <br>
              </v-card-title>
                <v-card-text class="headline font-weight-bold pt-5">
                  "Santiago de Chile #31, <br>
                   Colonia Nueva Mina Norte, <br>
                   Minatitlán, Ver. México. <br>
                   C.p. 96734"
                </v-card-text>
                  <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.0389386050297!2d-89.59621258591244!3d20.991076194441515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f56712e8fea7881%3A0x83b6a851b1d52dc0!2zQ2FsbGUgMzHhtK4gMTQ1LCBOdWV2YSBBbGVtw6FuLCA5NzE0NiBNw6lyaWRhLCBZdWMu!5e0!3m2!1ses!2smx!4v1544815314466"
                      width="460" 
                      height="350" frameborder="0" style="border:0"
                      class="pl-5">
                  </iframe> -->
              </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

    <!-- <section>
      <v-card >
        <v-img
          src="@/assets/img/icons/Fondo-pie.jpg"
          height="260"
        >          
        <br>
          <v-card-text class="pt-5 pr-5 mr-5 display-1 text-xs-center font-weight-light white--text">
            <h4>¡Gracias por tu visita!</h4>
          </v-card-text>
          <v-card-text class="title text-xs-center font-weight-thin white--text">
            <h4>gveronica04@hotmail.com | +52 (922) 22 80639</h4>
          </v-card-text>
        </v-img>
      </v-card>
    </section> -->

    <section>
      <v-footer
      height="auto"
      color="#CB6CE6"
     >
       <v-layout
       justify-space-around row fill-height
        wrap
       >
          <V-dialog v-model="dialog9" width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Términos y condiciones</span>
              </v-card-title>
              <v-card-text
                >
                <p>Esta página de Internet y su contenido, incluyendo los textos, gráficos, logos e imágenes, son propiedad de VERÓNICA ALEJANDRA GARCÍA CASTILLO. Está prohibida su reproducción total o parcial, su traducción, inclusión, transmisión, almacenamiento o acceso a través de medios analógicos, digitales o de cualquier otro sistema o tecnología creada, sin autorización previa y escrita de VERÓNICA ALEJANDRA GARCÍA CASTILLO.
                Los usuarios pueden recuperar, exhibir, imprimir, copiar o descargar cualquier material de este sitio exclusivamente para uso personal y no comercial, siempre y cuando se haga expresa mención de la propiedad en cabeza de VERÓNICA ALEJANDRA GARCÍA CASTILLO.
                Con respecto a los contenidos que aparecen en la página Web de VERÓNICA ALEJANDRA GARCÍA CASTILLO, el usuario se obliga a:
                Usar los contenidos de forma diligente, correcta y lícita.
                No suprimir, eludir, o manipular el copryght (derecho de autor) y demás datos que identifican los derechos de la empresa VERÓNICA ALEJANDRA GARCÍA CASTILLO 
                VERÓNICA ALEJANDRA GARCÍA CASTILLO no será responsable del uso indebido que hagan los usuarios de su página Web.
                El visitante o usuario de la página Web de VERÓNICA ALEJANDRA GARCÍA CASTILLO no enviará o transmitirá en este sitio Web o hacía el mismo, a otros usuarios o a cualquier persona, cualquier información de alcance obsceno, difamatorio, injuriante, calumniante contra VERÓNICA ALEJANDRA GARCÍA CASTILLO o contra los responsables de la administración de la página Web.
                El visitante o usuario de este sitio Web, no incurrirá en y desde el mismo, en conductas ilícitas como daños o ataques informáticos, interceptación de comunicaciones, infracciones al derecho de autor, uso no autorizado de terminales, usurpación de identidad, revelación de secretos o falsedad en los documentos.
                Información y sitios Web de terceros
                La página Web puede ofrecer hipervínculos o accesos a páginas Web y contenidos de otras personas, con el propósito de informar al Usuario sobre la existencia de otras fuentes susceptibles de ampliar los contenidos que ésta ofrece, o que guardan relación con aquéllos. VERÓNICA ALEJANDRA GARCÍA CASTILLO no controla, refrenda ni garantiza el contenido incluido en dichos sitios, tampoco se responsabiliza del funcionamiento o accesibilidad de las páginas Web vinculadas, ni sugiere, invita o recomienda la visita a las mismas, por lo que tampoco será responsable del resultado obtenido.
                El usuario acepta que la empresa no es responsable de ningún contenido, enlace asociado, recurso o servicio relacionado con el sitio de un tercero. Asimismo, el Usuario acepta que VERÓNICA ALEJANDRA GARCÍA CASTILLO no será responsable de ninguna perdida o daño de cualquier tipo que se derive del uso que se realice de los contenidos de un tercero.
                VERÓNICA ALEJANDRA GARCÍA CASTILLO  no se responsabiliza por cualquier consecuencia derivada del ingreso indebido de terceros a la base de datos y/o por alguna falla técnica en el funcionamiento y/o conservación de datos en el sistema en cualquiera de los menús de su página Web.
                Igualmente, VERÓNICA ALEJANDRA GARCÍA CASTILLO no podrá garantizar la disponibilidad de los servicios en línea y de la información que los usuarios requieran en determinado momento. Tampoco incurrirá en responsabilidad con el usuario o terceros, cuando su página Web no se encuentre disponible.
                VERÓNICA ALEJANDRA GARCÍA CASTILLO no responderá en ningún caso y bajo ninguna circunstancia, por los ataques o incidentes contra la seguridad de su página Web o contra sus sistemas de información; o por cualquier exposición o exceso no autorizado, fraudulenta o ilícito a su página Web y que puedan afectar las confidencialidad, integridad o autenticidad de la información publicada o asociada con los contenidos y servicios que se ofrecen en ella.
                Responsabilidad por la información contenida
                Debido a que en la actualidad los medios técnicos no permiten garantizar la absoluta falta de injerencia de la acción de tercera personas en las páginas Web, VERÓNICA ALEJANDRA GARCÍA CASTILLO de ninguna manera asegura la exactitud y/o veracidad de todo o parte de la información contenida en su página, ni de su actualización, ni que dicha información haya sido alterada o modificada en todo o en parte, luego de haber sido publicada en la página, ni cualquier otro aspecto o característica de lo publicado en el sitio o en los enlaces, respectivamente.
                VERÓNICA ALEJANDRA GARCÍA CASTILLO no controla ni garantiza la ausencia de virus ni de otros elementos en los contenidos que puedan producir alteraciones en su sistema informático (software y hardware) o en los documentos electrónicos y ficheros almacenados en su sistema informático.
                Ley aplicable y jurisdicción
                El usuario no podrá manifestar ante VERÓNICA ALEJANDRA GARCÍA CASTILLO o ante a una autoridad judicial o administrativa, la aplicación de condición, norma o convenio que no esté expresamente incorporado en las presentes condiciones de uso.
                Estas condiciones serán gobernadas por la leyes de la República Mexicana, en los aspectos que no estén expresamente regulados en ellas.
                Si cualquier disposición de estas condiciones pierde validez o fuerza obligatoria. por cualquier razón, todas las demás disposiciones, conservan su fuerza obligatoria, carácter vinculante y generarán todos sus efectos.
                Para cualquier efecto legal o judicial, el lugar de las presentes condiciones es la ciudad de Minatitlán, y cualquier controversia que surja de su interpretación o aplicación se someterá  los jueces de la República Mexicana.
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" flat="flat" @click="dialog9 = false">Lo he leído</v-btn>
              </v-card-actions>
            </v-card>
          </V-dialog>
          <v-dialog v-model="dialog10" width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Política de Privacidad</span>
              </v-card-title>
              <v-card-text
                >
                <p>VERÓNICA ALEJANDRA GARCÍA CASTILLO  (en adelante VAC), con domicilio en Santiago de Chile #31, Colonia Nueva Mina Norte, Minatitlán, Ver. México, C.p. 96734, con el RFC: GACV780418PK3 es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.
                  DATOS PERSONALES QUE RECABAMOS
                  VERONICA ALEJANDRA GARCÍA CASTILLO podrá recabar de usted los siguientes datos personales: nombre completo, domicilio, RFC, teléfono, correo electrónico, datos bancarios, referencias personales. Asimismo, podremos solicitar de Usted copia de identificación oficial, comprobante de domicilio, contrato de arrendamiento de inmueble o comprobantes de pago de predial, en su caso.
                  USO DE SUS DATOS PERSONALES
                  Sus datos personales serán utilizados para las siguientes finalidades: (i) proveer los servicios y productos requeridos por Usted; (ii) informar sobre cambios o nuevos productos que estén adquiridos por el cliente; (iii) dar cumplimiento a obligaciones contraídas con Usted; (d) evaluar la calidad del servicio; (iv) enviarle ofertas, promociones o publicidad relacionadas con nuestros productos y servicios.
                  TRANSMISIÓN DE DATOS
                  Nos comprometemos a no transferir su información personal a terceros sin su consentimiento. salvo las excepciones previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
                  ACCESSO, RETIFICACIÓN, CANCELACIÓN Y OPOSICIÓN DE DATOS PERSONALES
                  Usted tiene derecho a acceder a sus datos personales que poseemos y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o incompletos; cancelarlos cuando considere que no se requieren para alguna de las finalidades señalados en el presente aviso de privacidad, estén siendo utilizados para finalidades no consentidas o haya finalizado la relación de servicio, o bien, oponerse al tratamiento de los mismos para fines específicos.
                  Los mecanismos que se han implementado para el ejercicio de dichos derechos son a través de la presentación de la solicitud respectiva ante nuestra área de servicio al cliente:
                                        * Número telefónico: 922 22 806 39
                                        * Correo electrónico: gveronica04@hotmail.com
                                        * Domicilio:   Santiago de Chile #31, Colonia Nueva Mina Norte, Minatitlán, Ver. México, C.p. 96734
                  Podrá presentar su solicitud en cualquiera de los medios de comunicación mencionados anteriormente.
                  Su solicitud deberá contener la siguiente información: (i) nombre completo, (ii) derecho que desee ejercer, (iii) datos que requiera acceder, ratificar, cancelar u oponerse a su tratamiento, (iv) en caso que se solicite la rectificación o actualización de sus datos, deberá proporcionar el dato que sustituirá el anterior, (v) en caso de oposición al trato de sus datos, deberá indicar el dato personal y el uso al cual se opone, (vi) en caso de cancelación o acceso, deberá indicar el dato a cancelar o acceder, según sea el caso.
                  Para mayor información, favor de comunicarse al área de servicio al cliente mencionada anteriormente.
                  REVOCACIÓN DE CONSENTIMIENTO
                  En todo momento, Usted podrá revocar el consentimiento que nos ha otorgado para el tratamiento de su datos personales, a fin de que dejemos de hacer uso de los mismos. Para ello, es necesario que presente su petición en el área de servicio al cliente, de conformidad con el procedimiento establecido en el punto anterior.
                  Si como consecuencia de la revocación de su consentimiento no es posible brindar el servicio o producto contratado por Usted, se entenderá que el mismo se ha dado por terminado por parte suya, sin ninguna responsabilidad para VERONICA ALEJANDRA GARCÍA CASTILLO.
                  MODIFICACIONES AL AVISO DE PRIVACIDAD
                  Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones a las presentes políticas de privacidad. Estas modificaciones estarán disponibles al público a través de los siguientes medios: (i) anuncios visibles en nuestros establecimientos o centros de atención a clientes, (ii) trípticos o folletos disponibles en nuestro establecimientos o centros de atención a clientes, (iii) en nuestra página de Internet https://www.veronicaalejandra.com sección de políticas de privacidad, (iv) o se la haremos llegar al último correo electrónico que nos haya proporcionado.
                  Fecha de última actualización: Diciembre 04 de 2024
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" flat="flat" @click="dialog10 = false">Lo he leído</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <v-flex
          black
          py-3
          text-xs-right
          white--text
          xs12
          row
        >
          <v-btn unhover class="black white--text mr-5 pr-5">
            <h4><strong>VerónicaAlejandra</strong> &copy;2024</h4>
          </v-btn>
          
          <v-btn disabled class="black white--text mr-5 pr-5"></v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            class="ml-5 font-weight-thin caption"
            slot="activator" color="white"
            flat
            @click="dialog9 = true"
            ><h4>Términos y condiciones</h4>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="font-weight-thin caption"
            slot="activator" color="white"
            flat
            @click="dialog10 = true"
            ><h4>Política de Privacidad</h4>
          </v-btn>
          <v-spacer></v-spacer>
        </v-flex>
       </v-layout>
      </v-footer>
    </section>
    
  </v-content>
</template>

<script>
export default {
    
    data: () => ({

      length: 3,
      onboarding: 0,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      dialog6: false,
      dialog7: false,
      dialog8: false,
      dialog9: false,
      dialog10: false,
      dialog11: false,
      dialog12: false,
      dialog13: false,
      icons: [
        'fab fa-facebook',
        'fab fa-twitter',
        'fab fa-google-plus',
        'fab fa-linkedin',
        'fab fa-instagram'
      ],
      items: [
          {
            src: '@/assets/img/icons/Fondo1.jpg'
          },
          {
            src: '@/assets/img/icons/Fondo2.jpg'
          },
          {
            src: '@/assets/img/icons/Fondo3.jpg'
          },
        ],
    }),

    methods: {
      next () {
        this.onboarding = this.onboarding + 1 === length
          ? 0
          : this.onboarding + 1
      },
      prev () {
        this.onboarding = this.onboarding - 1 < 0
          ? this.length - 1
          : this.onboarding - 1
      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  /* h2, h4, h5, h6, {
    font-family: 'Maven Pro', sans-serif;
  } */
  
  .carousel {
  height: 1200px;
}
  .v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}

avatar.borde {
  outline-width: medium;
}
</style>
