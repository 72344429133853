import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'; // Asegúrate de tener la biblioteca de íconos instalada

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'mdi', // Especifica la biblioteca de íconos
  },
};

export default new Vuetify(opts);
